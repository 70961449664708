html{
  height: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root{
  height: 100%;
}


.carousel-wapper h2 .carousel-cursor.hidden {
  visibility: hidden; }

.background {
  overflow: hidden;
  z-index: -100;
  position: absolute;
  top: 0; }

.badges-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center; }

img {
  width: 30px;
  padding: 0.5em; }

.home-wrapper {
  height: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

body {
  height: 100%;
  width: 100%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
  background: none; }

.App {
  height: 100%; }

a {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
  text-decoration: underline; }

